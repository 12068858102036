import React from "react";
import { Column } from "react-table";
import { Format, MarketCode, MarketCodeLabel } from "@homesusa/core";
import { Email } from "core/interfaces";
import { TransactionType, TransactionTypeLabel } from "core/enum";
import { EmailSource, EmailSourceLabel } from "../enums";

type emailColumn = Array<Column<Email>>;

export const useEmailGridColumns = (): emailColumn => {
  const columns: emailColumn = React.useMemo(
    () => [
      {
        Header: "Row",
        Cell: ({ row }: { row: { index: number } }): number => row.index + 1,
      } as unknown as Column<Email>,
      {
        Header: "Created",
        accessor: (data: Email): string => Format.DateTime(data.transferedOn),
        id: "transferedOn",
      },
      {
        Header: "MLS #",
        accessor: (data: Email): string => data.mlsNum ?? "",
        id: "mlsNum",
      },
      {
        Header: "Transaction Type",
        accessor: (data: Email): string =>
          TransactionTypeLabel.get(data.transactionType as TransactionType) ??
          data.transactionType,
      },
      {
        Header: "Market",
        accessor: (data: Email): string =>
          MarketCodeLabel.get(data.market as MarketCode) ?? data.market,
      },
      {
        Header: "Builder",
        accessor: (data: Email): string => data.companyName ?? "",
        id: "company",
      },
      {
        Header: "City",
        accessor: (data: Email): string => data.city ?? "",
      },
      {
        Header: "Subdivision",
        accessor: (data: Email): string => data.subdivision ?? "",
      },
      {
        Header: "Address",
        accessor: (data: Email): string => data.address ?? "",
      },
      {
        Header: "Zip Code",
        accessor: (data: Email): number => data.zipCode ?? 0,
      },
      {
        Header: "Entered By",
        accessor: (data: Email): string => data.createdBy ?? "",
      },
      {
        Header: "Source",
        accessor: (data: Email): string =>
          EmailSourceLabel.get(data.source as EmailSource) ?? data.source,
      },
      {
        Header: "Sender Email",
        accessor: (data: Email): string => data.senderEmail ?? "",
      },
      {
        Header: "Sender Name",
        accessor: (data: Email): string =>
          `${data.senderFirstName} ${data.senderLastName}`,
      },
      {
        Header: "Lead Phone",
        accessor: (data: Email): string => Format.PhoneNumber(data.phoneNumber),
        id: "phoneNumber",
      },
      {
        Header: "Lead Receiver",
        accessor: (data: Email): string => data.receiverEmails ?? "",
        id: "receiverEmails",
      },
    ],
    []
  );

  return columns;
};
